.img-laquetediana-product-small{
    position: absolute;
    transform: scale(0.15);
    top: -380px;
}

.img-left-plant{
    position: absolute;
    left: -570px;
    top: -565px;
    transform: scale(0.2);
}

.img-right-plant{
    position: absolute;
    top: -380px;
    left: 95px;
    transform: scale(0.2);
}

.img-laquetediana-product-small-4{
    top: -488px !important;
}

.img-laquetediana-product-small-5{
    top: -690px !important;
}

.img-left-plant-2{
    left: -90px !important;
    top: -31px !important;
    transform: none !important;
}

.img-right-plant-2{
    left: 293px !important;
    top: -28px !important;
    transform: none !important;
}

.img-right-plant-4{
    top: -393px;
    left: 45px;
}

.img-left-plant-4
{
    left: -254px;
    top: -414px;
}

.img-left-plant-5,
.img-left-plant-6{
    left: -438px;
    top: -479px;
}

.img-right-plant-5{
    position: absolute;
    top: -448px;
    left: -4px;
    transform: scale(0.2);
}

.img-right-plant-6{
    top: -567px;
    left: -73px;
}
