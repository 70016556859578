.img-laquetediana-name{
    position: absolute;
    top: 50px;
    width: 300px;
    right: 50px;
}

.img-laquetediana-product{
    position: absolute;
    transform: scale(0.24);
    top: -230px;
}

.question-text{
    position: absolute;
    top: 570px;
    font-size: 50px !important;
    font-family: "FormaDJRText-Medium" !important;
    font-weight: normal !important;
    letter-spacing: 4px;
}

.commencer-button{
    position: absolute;
    bottom: 85px;
    width: 60% !important;
}

.wizard-button{
    height: 40px !important;
}

.commencer-button-text{
    letter-spacing: 3px;
    margin-top: 10px !important;
    font-family: "FormaDJRText-Medium" !important;
}

.img-laquetediana-product-5{
    top: -520px !important;
}
