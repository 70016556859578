.footer-container {
    background-color: #5BAC95;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer-text {
    color: white;
    font-family: 'FormaDJRText-Medium' !important;
    font-size: 20px;
    margin-left: -60px;
}

.footer-icon {
    margin: 0 3px;
    height: 48px;
    right: -45px;
}

.footer-icon-small {
    height: 30px;
    margin-right: 15px;
}

.footer-icon-small-retour{
    height: 30px;
    margin-left: 15px;
}
