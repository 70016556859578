.login-button-title{
    font-size: 20px;
    letter-spacing: 3px;
    margin-top: 10px !important;
    font-family: "FormaDJRText-Medium" !important;
}


.login-button{
    height: 60px;
    width: 250px;
    display: block;
    margin: 0 auto;
}

.input-email-login{
    font-size: 14px;
    font-family: 'FormaDJRText-Regular' !important;
}

.bienvenue-screen-img-left-top-login{
    position: absolute;
    top: -70px;
    width: 300px;
    left: -139px;
}
.bienvenue-screen-img-right-top-login{
    position: absolute;
    top: 0px;
    width: 200px;
    right: -67px;
}
.bienvenue-screen-img-left-bottom-login{
    position: absolute;
    top: 230px;
    width: 300px;
    left: -130px;
}

.bienvenue-screen-img-right-bottom-login{
    position: absolute;
    top: 365px;
    width: 150px;
    right: -53px;
}
.bienvenue-screen-img-laquetediana-name-login{
    position: absolute;
    top: 194px;
    /*width: 150px;*/
    right: 33px;
}
