@font-face {
    font-family: 'FormaDJRText-Medium';
    src: url('../fonts/web/FormaDJRText-Medium-Testing.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FormaDJRText-Bold';
    src: url('../fonts/web/FormaDJRText-Bold-Testing.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'FormaDJRText-Regular';
    src: url('../fonts/web/FormaDJRText-Regular-Testing.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'FormaDJRText-Extra-Bold';
    src: url('../fonts/web/FormaDJRText-ExtraBold-Testing.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

*{
    font-family: 'FormaDJRText-Bold' !important;
    font-size: 24px;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto !important; /* Oculta el scroll */
  }

.first-background{
    background-image: url('../img/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
}

.bienvenue-screen-img-left-top{
    position: absolute;
    top: -61px;
    width: 250px;
    left: -170px;
}
.bienvenue-screen-img-right-top{
    position: absolute;
    top: 0px;
    width: 150px;
    right: -104px;
}
.bienvenue-screen-img-left-bottom{
    position: absolute;
    top: 230px;
    width: 250px;
    left: -162px;
}

.bienvenue-screen-img-right-bottom{
    position: absolute;
    top: 306px;
    width: 150px;
    right: -113px;
}
.bienvenue-screen-img-laquetediana-name{
    position: absolute;
    top: 194px;
    /*width: 150px;*/
    right: -22px;
}

.step-bienvenue{
    width: 90%;
}

.footer-logo-screen {
    border-top: 12px solid #5BAC95;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.screen2-img-left-top{
    position: absolute;
    top: -31px;
    width: 250px;
    left: -116px;
}
.screen2-img-right-top{
    position: absolute;
    top: 0px;
    width: 150px;
    right: -51px;
}
.screen-img-laquetediana-name-small{
    position: absolute;
    top: 25px;
}

.screen2-img-right-bottom {
    position: absolute;
    bottom: 16px;
    width: 200px;
    right: -110px;
}
.mt-0{
    margin-top: 0 !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.mb-20{
    margin-bottom: 20px !important;
}
.mb-50{
    margin-bottom: 50px !important;
}
.mt-70{
    margin-top: 70px !important;
}

.text-center{
    text-align: center;
    text-transform: uppercase;
}

.text-uppercase{
    text-transform: uppercase !important;
}
.main-ar{
    display: flex;
    flex-direction: column;
    /*min-height: 100vh;*/
    /*height: 926px;*/
    /*overflow-y: auto;*/
}
.main-container{
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*width: 1920px !important;*/
    /*height: 1080px !important;*/
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 776px;
    /*overflow-y: auto;*/
}

h4.title{
    color: #406F60;
    font-size: 40px;
    letter-spacing: 4px;
}

h3.title-middle{
    color: #406F60;
    font-size: 18px !important;
    font-family: 'FormaDJRText-Bold' !important;
}

.screen2-paragraph-middle{
    color: #406F60;
    font-size: 18px !important;
    font-family: 'FormaDJRText-Regular' !important;
    text-transform: none !important;
}

.screen3-paragraph-middle{
    color: #406F60;
    font-size: 22px !important;
    font-family: 'FormaDJRText-Bold' !important;
}

.screen3-text-middle{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90% !important;
}

input[type="text"] {
    width: 100%;
    border: 1px solid #D6D8DB;
    border-radius: 10px;
    margin: 8px 0;
    outline: none;
    padding: 15px;
    box-sizing: border-box;
    transition: 0.3s;
    color: #848A94;
    font-size: 20px;
}

input[type="text"]:focus {
    border-color: #A0E56A;
    box-shadow: 0 0 8px 0 #A0E56A;
}

.inputWithIcon input[type="text"] {
    padding-left: 50px;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon i {
    position: absolute;
    left: 0;
    top: 15px;
    padding: 9px 14px;
    color: #aaa;
    transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus + i {
    color: #A0E56A;
}

.inputWithIcon.inputIconBg i {
    background-color: #aaa;
    color: #fff;
    padding: 9px 4px;
    border-radius: 4px 0 0 4px;
}

.inputWithIcon.inputIconBg input[type="text"]:focus + i {
    color: #fff;
    background-color: #A0E56A;
}

.fa-icon {
    font-family: 'Font Awesome 6 Free', 'Font Awesome 6 All', sans-serif !important;
}

.inputWithIcon input::placeholder {
    color: #848A94;
    opacity: 1;
    background-color: transparent;
    font-size: 20px;
    font-weight: normal;
}

.step-wizard .subtitle{
    font-size: 22px;
    color: #406F60;
    font-family: 'FormaDJRText-Medium' !important;
    letter-spacing: 5px;
}

.step-wizard {
    margin-bottom: 0;
}

.step-wizard .title, .step-wizard .subtitle {
    margin-top: 0;
    margin-bottom: 0;
}

.wizard-button{
    color: #5BAB95 !important;
    border-radius: 50px !important;
    border: 1px solid #5BAC95 !important;
    line-height: 1 !important;
    padding: 0px !important;
    width: 90% !important;
    margin: 0 auto !important;
    display: block !important;
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 20px !important;
}

.wizard-button-screen2{
    color: #fff !important;
    /*border-radius: 50px !important;*/
    border: 1px solid #5BAC95 !important;
    line-height: 1 !important;
    padding: 60px !important;
    /*width: 90% !important;*/
    margin: 0 auto !important;
    display: block !important;
    margin-top: 50px !important;
    margin-bottom: 30px !important;
    font-size: 20px !important;
    border-bottom-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    height: 250px;
    width: 250px;
    background-color: #5BAD95 !important;
    font-family: 'FormaDJRText-Medium' !important;
}

.qr-icon-scanner-screen2 {
    margin-top: -25px;
}
.wizard-button-screen3{
    color: #fff !important;
    border-radius: 36px !important;
    border: 1px solid #5BAC95 !important;
    line-height: 1 !important;
    padding: 0px 2px 0px 2px!important;
    width: 250px !important;
    height: 45px;
    background-color: #5BAC95 !important;
    margin-bottom: 30px !important;
    font-family: 'FormaDJRText-Medium' !important;
    font-size: 20px !important;
}

.button-title{
    font-size: 20px;
}

.scanner-ar{
    height: 60vh;
}
#scene-ar-container{
    height: 100vh;
}
.a-enter-vr-button{
    display: none !important;
}
.container-images-scanner {
    position: relative;
    display: inline-block;
    height: 100%;
    margin-bottom: 80px;
}

.screen-img-opaca-scanner {
    display: block;
    width: 100%;
    height: auto;
    filter: blur(8px);
}

.screen-img-scanner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container-text-scanner{
    margin-bottom: 50px;
}

.screen-strong-text{
    font-size: 18px !important;
}

.screen-last-paragraph-middle{
    color: #406F60;
    font-size: 27px !important;
    font-family: 'FormaDJRText-Regular' !important;
    text-transform: uppercase !important;
    letter-spacing: 5px;
}

.footer-question-screen {
    background-color: #5BAC95;
    display: flex;
    justify-content: end;
    lign-items: center;
    /*height: 100%;*/
    width: 100%;
    height: 75px;
}
.screen-img-question-footer{
    margin-right: 65px;
    padding: 13px;
}


.footer-question-nav-screen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #5BAC95;
    width: 100%;
    height: 75px;
}

.footer-question-nav-screen .group-right {
    display: flex;
}

.screen-img1-question-footer{
    margin-left: 15px;
}

.screen-img2-question-footer{
    padding: 10px;
    height: 44px;
    margin-bottom: 10px;
}

.screen-img3-question-footer {
    padding: 18px;
    height: 28px;
    /* top: 50%; */
}

.bienvenue-screen-last-img-left-top{
    position: absolute;
    top: -61px;
    width: 250px;
    left: -115px;
}
.bienvenue-screen-last-img-right-top{
    position: absolute;
    top: 0px;
    width: 150px;
    right: -48px;
}
.bienvenue-screen-last-img-left-bottom{
    position: absolute;
    top: 230px;
    width: 250px;
    left: -104px;
}

.bienvenue-screen-last-img-right-bottom{
    position: absolute;
    top: 306px;
    width: 150px;
    right: -52px;
}
.bienvenue-screen-last-img-laquetediana-name{
    position: absolute;
    top: 194px;
    /*width: 150px;*/
    right: 37px;
}