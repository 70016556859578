.valider-button{
    position: absolute;
    bottom: 70px;
    width: 60% !important;
}

.question-block {
    position: absolute;
    background-color: transparent;
    text-align: center;
    margin: 20px auto;
    top: 35%;
}

.question-content {
    background-color: transparent;
    display: inline-block;
    text-align: left;
    padding: 20px;
    border-radius: 8px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
}

.form-check {
    margin: 30px 0;
    display: flex;
    align-items: center;
}

.question-title{
    font-family: "FormaDJRText-Extra-Bold" !important;
    color: #406F60;
    font-size: 30px;
}

.question-content-text{
    font-family: "FormaDJRText-Bold" !important;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    font-style: normal;
    text-align: center;
    color: #406f60;
}

.answer-text{
    font-family: "FormaDJRText-Regular" !important;
    color: #406F60;
    font-size: 20px;
    font-weight: normal;
    line-height: 20px;
    font-style: normal;
    text-align: left;
    height: 23px;
    padding-left: 10px;
    flex-grow: 1;
    word-wrap: break-word;
}

.form-check-input {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #5BAC95;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.form-check-input:checked {
    background-color: #406F60;
    border-color: #406F60;
}

.form-check-input:checked::before {
    content: '\2713'; /* Unicode checkmark */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: white;
}

.form-check-label {
    cursor: pointer;
}
.check-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Espacio entre la casilla de verificación y el texto */
}


