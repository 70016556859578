.suivant-button{
    position: absolute;
    top: 700px;
    width: 60% !important;
}

.text-block{
    position: absolute;
    background-color: transparent;
    margin: 20px auto;
    top: 45%;
    left: 15%;
}

.results-form-check-input {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #A0E56A;
    border-radius: 4px;
    outline: none;
    cursor: none;
    position: relative;
    margin-right: 10px;
    top: 5px;
    background-color: white;
}

.results-form-check-input:checked {
    background-color: #A0E56A;
    border-color: #A0E56A;
}

.results-form-check-input:checked::after {
    content: '';
    position: absolute;
    left: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.results-answer-text{
    font-family: "FormaDJRText-Medium" !important;
    color: #A0E56A;
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    font-style: normal;
    text-align: left;
    height: 23px;
    padding-left: 10px;
}

.answer-description{
    position: absolute;
    top: 53%;
}

.text-description{
    text-align: center;
    margin: 5px;
}
